import { useEffect, useRef } from 'react';

const useParallaxOffset = (elementId, intensity) => {

    let element = null;

    const isInBetween = (value, min, max) => {
        return value <= max && value >= min
    }

    const updateScrollPosition = () => {

        if(element == null){
            element = document.getElementById(elementId);
        }

        const rect = element.getBoundingClientRect();
        const elTop = rect.y;
        const elBottom = elTop + rect.height;
        const elYCenter = rect.y + rect.height/2;
        const parallaxOffset = -elYCenter * intensity;

        element.style.transform = "translateY(" + parallaxOffset + "%)";
    }

    useEffect(() => {
        document.addEventListener('scroll', updateScrollPosition);
        return () => document.removeEventListener('scroll', updateScrollPosition);
    }, []);
}

export default useParallaxOffset;