import React from 'react';
import PropTypes from 'prop-types';
import styles from './Tools.module.css';

const Tools = (props) => {

  console.log(props.toolNames);
  const tools =  props.toolNames == undefined ? props.logos : [];
  console.log(tools.length);

  if(tools.length == 0){
    for(let i = 0; i < props.logos.length; i++){
      for(let j = 0; j < props.toolNames.length; j++){

        console.log(props.logos[i], props.toolNames[j]);
        if(props.logos[i].name != props.toolNames[j])
          continue;

        console.log(props.logos[i], props.toolNames[j]);
        tools.push(props.logos[i]);
      }
    }
  }

  console.log(tools);

  const listItems = tools.map((logo, index) =>
    <li key={index}>
      <div className={styles.ToolWrapper}>
        <img src={"./" + logo.name + "-white.png"} alt={logo.displayName} />
        <div className={styles.Tooltip}>{logo.displayName}</div>
      </div>
    </li>
  );

  return (<div className={styles.Tools}>
    <h2>{props.heading}</h2>
    <ul>
      {listItems}
    </ul>
  </div>)
}

Tools.propTypes = {};

Tools.defaultProps = {
  logos: [
    {name: "unity", displayName: "Unity"}, 
    {name: "blender", displayName: "Blender"}, 
    {name: "cs", displayName: "C#"}, 
    {name: "cpp", displayName: "C++"}, 
    {name: "html", displayName: "HTML"}, 
    {name: "css", displayName: "CSS"}, 
    {name: "reactjs", displayName: "ReactJS"}, 
    {name: "nodejs", displayName: "NodeJS"}, 
    {name: "photoshop", displayName: "Adobe Photoshop"}, 
    {name: "xd", displayName: "Adobe XD"}, 
    {name: "github", displayName: "GitHub"},
    {name: "rider", displayName: "Rider"}, 
    {name: "vscode", displayName: "VS Code"},
    {name: "processing", displayName: "Processing"},
  ]
};

export default Tools;
