import React from 'react';
import PropTypes from 'prop-types';
import styles from './Section.module.css';
import Header from '../Header/Header';

const Section = (props) => {


  return (
  <div className={`${styles.Section} SectionWrapper Dark`} sectionname={props.title}>

    <Header title={props.title} subtitle={props.subtitle} backgroundExt={props.backgroundExt}></Header>

    <div className="SectionContent ">
      <div className="container">
        {props.children}
      </div>
    </div>
  </div> )
}

Section.propTypes = {};

Section.defaultProps = {};

export default Section;
