import { useEffect, useRef } from 'react';

const useSectionInView = (offset, callback) => {

    const sectionRef = useRef();

    let nodeList = document.querySelectorAll('[sectionname]');
    
    const updateScrollPosition = () => {
        let bestMatch = {sectionName:"None", delta: 10000000};
        const windowHeight = window.innerHeight;
        // console.log("Window Height: ", windowHeight);

        if(nodeList.length == 0){
            nodeList = document.querySelectorAll('[sectionname]');
        }

        for (let i = 0; i < nodeList.length; i++) {
            const element = nodeList.item(i);
            const sectionName = element.getAttribute("sectionname");
            const delta = window.scrollY - element.offsetTop;
            const offsetTop = element.offsetTop;

            // if (!bestMatch.sectionName) 
            //     bestMatch = { sectionName, delta, offsetTop};
        
            if (delta < bestMatch.delta && delta >= offset && delta < offset + windowHeight) {
                bestMatch = { sectionName, delta, offsetTop};
            }
        }

        if(sectionRef.current != bestMatch.sectionName){
            // console.log(bestMatch.sectionName, bestMatch.offsetTop, bestMatch.delta);
            sectionRef.current = bestMatch.sectionName;
            callback(bestMatch.sectionName, bestMatch.offsetTop);
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', updateScrollPosition);
        return () => document.removeEventListener('scroll', updateScrollPosition);
    }, []);
}

export default useSectionInView;