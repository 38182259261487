import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Gallery.module.css';

const Gallery = ({items, toggleOverlay}) => {

  for(let i = 0; i < items.length; i++){
    let nextIndex = i + 1 > items.length-1 ? 0 : i + 1;
    let prevIndex = i - 1 < 0 ? items.length-1 : i - 1;

    items[i].next = items[nextIndex];
    items[i].prev = items[prevIndex];
    items[i].nextIndex = nextIndex;
    items[i].prevIndex = prevIndex;
  }

  const [content, setContent] = useState();

  const listItems = items.map((item, index) =>
    <li key={index} onClick={() => toggleOverlay(true, items, index)}>
      <img src={"./" + item.thumb} alt={item.title}/>
    </li>
  );

  return (
  <div className={styles.Gallery}>
    <h2>Gallery</h2>
    <ul>
      {listItems}
    </ul>
  </div>)
}

Gallery.propTypes = {};

Gallery.defaultProps = {};

export default Gallery;
