import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.css';
import SectionProgress from '../SectionProgress/SectionProgress'

const Footer = () => (
  <div className={styles.Footer}>
    <SectionProgress numItems="6" currentItem="3"></SectionProgress>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
