import {React, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './GalleryOverlay.module.css';
import SectionProgress from '../SectionProgress/SectionProgress';

const GalleryOverlay = ({toggleOverlay, visible, items, activeIndex}) => {

  const ignoreCloseElementNames = ["BUTTON", "IMG", "VIDEO"];
  const activeVideo = useRef(null);

  const stopActiveVideo = () => {
    if(activeVideo.current != null){
      activeVideo.current.pause();
    }
  }

  const playActiveVideo = () => {
    if(activeVideo.current != null){
      activeVideo.current.currentTime = 0;
      activeVideo.current.play();
    }
  }

  useEffect(() => {
    playActiveVideo();
  }, [activeIndex]);

  const changeClick = (event, items, index) => {
    stopActiveVideo();
    toggleOverlay(true, items, index);
  }

  const closeClick = (event) => {
    console.log(event.target.tagName);
    if(ignoreCloseElementNames.indexOf(event.target.tagName) > -1){
      return;
    }
    stopActiveVideo();
    toggleOverlay(false, items, activeIndex);
  }

  const renderContent = (items, activeIndex) => {
    if(items == null){
      return "";
    }

    const listItems = items.map((item, index) =>
      <li key={index}>
        {
            item.type == "image" ? 
            <img src={`./${item.full}`} alt={item.title}/> : 
            <video 
              ref={index == activeIndex ? activeVideo : null}
              src={`./${item.full}`} 
              alt={item.title} 
              autoPlay={index == activeIndex} 
              loop
            />
        }
        <p>{item.title}</p>
      </li>
    );

    const ulStyles = {
      transform: "translateX(" + -(100/items.length * activeIndex) + "%)",
      width: items.length * 100 + "%"
    }

    return (<div className="gallery-overlay-content">
        <ul className='carousel' style={ulStyles}>
          {listItems}
        </ul>

        <div className="gallery-buttons">
          <button className="prev" onClick={(event) => changeClick(event, items, items[activeIndex].prevIndex)}>&lt;</button>
          <button className="next" onClick={(event) => changeClick(event, items, items[activeIndex].nextIndex)}>&gt;</button>
        </div>
      </div>
    );
  }

  return (
    <div 
      className={`${styles.GalleryOverlay} gallery-overlay ${visible ? "gallery-visible" : ""}`} 
      id="gallery-overlay" 
      onClick={(event) => closeClick(event)}
    >
      {renderContent(items, activeIndex)}

      <div className="section-progress-wrapper">
        <SectionProgress numItems={items.length} currentItem={activeIndex} isProgress={false}></SectionProgress>
      </div>
    </div>
  );
}

GalleryOverlay.propTypes = {};

GalleryOverlay.defaultProps = {};

export default GalleryOverlay;
