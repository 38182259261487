import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionProgress.module.css';

const SectionProgress = ({numItems, currentItem, progress}) => {
  const listItems = [];
  const width = {width: (100.0 / numItems) + '%'};

  for(let i = 0; i < numItems; i++){
    const active = progress ? i <= currentItem : i == currentItem;

    listItems.push(<li 
      key={i} 
      className={`${active ? styles.Active : ''} ${styles.ProgressBlock}`}
      style={width}>
    </li>);
  }

  return (
    <div className={styles.SectionProgress}>
      <ul>
        {listItems}
      </ul>
    </div>
  );
}

SectionProgress.propTypes = {};

SectionProgress.defaultProps = {};

export default SectionProgress;
