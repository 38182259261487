import React from 'react';
import PropTypes from 'prop-types';
import styles from './ScrambleText.module.css';
import useAnimation from '../../helpers/useAnimation';
import getRandomWord from '../../helpers/utils';
import { useState, useEffect, useRef } from 'react';

const ScrambleText = ({displayText, active, duration}) => {
  
  const [text, setText] = useState(displayText);
  const animate = useRef(false);

  useEffect(() => {
    animate.current = true;

    setTimeout(function () {
      animate.current = false;
    }, duration * 1000);

  }, [active]);

  useAnimation((deltaTime) => {
    if(animate.current){
      setText(getRandomWord(displayText.length));
    }else{
      setText(displayText);
    }
  });

  return (
    <div className={styles.ScrambleText}>
      {text}
    </div>
  );
}

ScrambleText.propTypes = {};

ScrambleText.defaultProps = {};

export default ScrambleText;
