import logo from './logo.svg';
import './App.css';

import Footer from './components/Footer/Footer';
import Tools from './components/Tools/Tools';
import Section from './components/Section/Section';
import Gallery from './components/Gallery/Gallery';
import GalleryOverlay from './components/GalleryOverlay/GalleryOverlay';
import { useState } from 'react';

function App() {

  const [galleryVisible, setGalleryVisible] = useState(false);
  const [galleryItems, setGalleryItems] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const toggleOverlay = (visible, items, index) => {
    // console.log(item);
    setGalleryVisible(visible);
    setGalleryItems(items);
    setGalleryIndex(index)
  }

  return (
    <div className="App">

      <GalleryOverlay 
        toggleOverlay={toggleOverlay}
        visible={galleryVisible}
        items={galleryItems}
        activeIndex={galleryIndex}
      ></GalleryOverlay>

      <section className="landing">
          <img className="logo" src="./logo.gif" alt="Donderbos / Portfolio" />
          {/* <p>// aka <b>Henk Scheepers</b></p> */}
          <img className="scroll" src="./scroll-arrow.png" alt="scroll down" />
      </section>
      
      <Section title="About Me" subtitle="Henk Scheepers" backgroundExt=".gif">
        <div className="mb-3"></div>
        <div className="row">
          <div className="col-lg-4 order-sm-1 order-lg-1 main-copy-col">
            <div className="main-copy">
              <p><span className="emphasise">Hello.</span>My name is Henk Scheepers. I am programmer with an Honors degree in Multimedia from the University of Pretoria. I thrive at the intersection between technology, design, and art.</p>
              <p>I have just over 14 years of experience working on projects ranging from developing websites to interactive installations. My broad range of skills culminates in, and complements my passion for game development.</p>
              <p>The past 5 years I have worked in the education industry as a lecturer in Game Development. Modules I teach range from Introduction to Programming all the way to Artificial Intelligence and 3D Graphics Programming.</p>
            </div>
          </div>

          <div className="col-sm-6 col-lg-4 order-sm-3 order-lg-2 secondary-copy">
            <Tools heading="Tools I like to use"></Tools>
            <div className="mb-5 d-md-none"></div>
            <div className="mb-5"></div>

            <h2>Connect with me</h2>
            <p>
              <a href='https://www.linkedin.com/in/henk-scheepers-16a5b659/' target='_blank'>LinkedIn</a> / <a href='https://www.instagram.com/donderbosgames/' target='_blank'>Instagram</a> / <a href="mailto:henk@donderbos.com">Email</a>
            </p>
            <div className="mb-5 d-md-none"></div>
          </div>

          <div className="col-sm-6 col-lg-4 order-sm-2 order-lg-3">
            <img src="./me3.jpg" alt="" />
            <div className="mb-5 d-md-none"></div>
          </div>
        </div>
      </Section>

      <Section title="avoid" subtitle="Game Development" backgroundExt=".gif">
        <div className="mb-2"></div>
        <div className="row">
          <div className="col-lg-4 main-copy-col">
            <div className="main-copy">
              <p><span className="emphasise">ΔVØID</span>is a first-person endless runner with a head turning twist&mdash;you rotate the world around you to avoid obstacles.</p>
              <p>I've been toying with the idea of manipulating gravity for a few years, but one day it just clicked as the main mechanic in a runner game. The aim was to create a game that feels extremely polished and addictive, but wouldn't take ages to complete. I achieved that with AVOID.</p>
              <p>AVOID's development started in July 2023 and it was launched on <a href='https://store.steampowered.com/app/2622220/AVOID/' target='_blank'>Steam</a> on 18 June 2024. It is the first game I completed as Donderbos Games, my indie game development studio.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 secondary-copy">
            <h2>Nerdy Stuff</h2>
            <div className="mb-1"></div>
            <p>To save time and to contribute to AVOID's unique aesthetic, the environments were generated using <a href='https://en.wikipedia.org/wiki/Cellular_automaton' target='_blank'>cellular automata</a>. The majority of the structures in the game were created by stacking multiple generations of a 2D CA, but a combination of both 2D and 3D CA rules were used.</p>
            <p>With the help of some custom written python scripts I generated the 3D meshes in Blender, to then import into Unity.</p>
            <p>You can find AVOID on Steam <a href='https://store.steampowered.com/app/2622220/AVOID/' target='_blank'>HERE</a></p>
            <div className="mb-5"></div>
            <Tools heading="Tools I used" toolNames={["unity", "blender", "rider", "photoshop"]}></Tools>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="mb-5 d-md-none"></div>
            <Gallery toggleOverlay={toggleOverlay} items={[
              {thumb:"avoid/avoid1-thumb.jpg", full:"avoid/avoid1.jpg", title:"Game Screenshot", type:"image"},
              {thumb:"avoid/avoid2-thumb.jpg", full:"avoid/avoid2.jpg", title:"Game Screenshot", type:"image"},
              {thumb:"avoid/avoid3-thumb.jpg", full:"avoid/avoid3.jpg", title:"Game Screenshot", type:"image"},
              {thumb:"avoid/avoid4-thumb.jpg", full:"avoid/avoid4.jpg", title:"Game Screenshot", type:"image"},
              {thumb:"avoid/avoid5-thumb.jpg", full:"avoid/avoid5.jpg", title:"Game Screenshot", type:"image"},
              {thumb:"avoid/avoid9-thumb.jpg", full:"avoid/avoid9.jpg", title:"Game Screenshot", type:"image"},
              {thumb:"avoid/avoid6-thumb.jpg", full:"avoid/avoid6.jpg", title:"Limited Edition Physical Copy", type:"image"},
              {thumb:"avoid/avoid7-thumb.jpg", full:"avoid/avoid7.jpg", title:"Limited Edition Physical Copy", type:"image"},
              {thumb:"avoid/avoid8-thumb.jpg", full:"avoid/avoid8.jpg", title:"Limited Edition Physical Copy", type:"image"}
            ]}></Gallery>
          </div>
        </div>
      </Section>

      <Section title="Echoplex" subtitle="Game Development" backgroundExt=".gif">
        <div className="mb-2"></div>
        <div className="row">
          <div className="col-lg-4 main-copy-col">
            <div className="main-copy">
              <p><span className="emphasise">Echoplex</span>is a time-bending puzzle platformer with a full-motion video story. Trapped in a mysterious looping simulation, you are followed by an “Echo” -- a duplicate of yourself who repeats everything you do.</p>
              <p>In 2014 I was approached by my colleague and friend, Tyron. Tyron, an independent  filmmaker, pitched the idea of Echoplex to me and asked if was interested in developing the game with him. </p>
              <p>Three years later, on 5 March 2018, Echoplex was released on <a href='https://store.steampowered.com/app/611060/ECHOPLEX/' target='_blank'>Steam</a>.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 secondary-copy">
            <h2>Nerdy Stuff</h2>
            <div className="mb-1"></div>
            <p>Being a team of only two making a game, it was important to be clever with how we spent our time. Early on in the project I developed level designer web-app using Angular and Firebase. The data from Firebase would then be imported into Unity and levels could be constructed with the press of a button.</p>
            <p>This meant levels could be prototyped, tested and iterated on quickly, and without the need for technical knowledge on how to put a level together in Unity.</p>
            <div className="mb-5"></div>
            <Tools heading="Tools I used" toolNames={["unity", "blender", "vscode"]}></Tools>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="mb-5 d-md-none"></div>
            <Gallery toggleOverlay={toggleOverlay} items={[
              {thumb:"echoplex/echoplex1-thumb.jpg", full:"echoplex/echoplex1.jpg", title:"Screenshot from the final game (1).", type:"image"},
              {thumb:"echoplex/echoplex2-thumb.jpg", full:"echoplex/echoplex2.jpg", title:"Screenshot from the final game (2).", type:"image"},
              {thumb:"echoplex/echoplex3-thumb.jpg", full:"echoplex/echoplex3.jpg", title:"Screenshot from the final game (3).", type:"image"},
              {thumb:"echoplex/echoplex4-thumb.jpg", full:"echoplex/echoplex4.jpg", title:"Screenshot from the final game (4).", type:"image"},
              {thumb:"echoplex/echoplex5-thumb.jpg", full:"echoplex/echoplex5.jpg", title:"Screenshot from the final game (5).", type:"image"},
              {thumb:"echoplex/echoplex9-thumb.jpg", full:"echoplex/echoplex9.jpg", title:"A level editor was developed to speed up the level design process", type:"image"},
              {thumb:"echoplex/echoplex6-thumb.jpg", full:"echoplex/echoplex6.jpg", title:"The JSON data generated by the level editor converted into level geometery.", type:"image"},
              {thumb:"echoplex/echoplex7-thumb.jpg", full:"echoplex/echoplex7.jpg", title:"Learning that using an image atlas can save some precious processing time.", type:"image"},
              {thumb:"echoplex/echoplex8-thumb.jpg", full:"echoplex/echoplex8.jpg", title:"Making sure the Echo is lit nicely.", type:"image"}
            ]}></Gallery>
            <div className="mb-5"></div>
          </div>
        </div>
      </Section>

      <Section title="Do-Rover" subtitle="Game Development" backgroundExt=".gif">
        <div className="mb-2"></div>
        <div className="row">
          <div className="col-lg-4 main-copy-col">
            <div className="main-copy">
              <p><span className="emphasise">Do-Rover</span>is a game and a ongoing passion project that I am working on in my spare time. The idea came from participating in the annual SA Game Jam event.</p>
              <p>In Do-rover you are given limited research funds to fund your expeditions on uncharted planets. The aim is to explore these planets in a rover and make new discoveries. New discoveries will add to your research funds.</p>
              <p>If your rover runs out of power the expedition ends and your rover is lost. You will need to use your funds to buy a new rover and start the next expedition. Run out of funds and it is game over.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 secondary-copy">
            <h2>Nerdy Stuff</h2>
            <div className="mb-1"></div>
            <p>Something clicked for me while working on this project. Textures are not just for displaying, but they are also amazing for storing all kinds of useful information.</p>
            <p>For instance, I could create a texture that maps to the level terrain. It has black pixels where there is dirt and white pixels where I have rocky terrain. I could then query this texture based on the rover's position in the level.</p>
            <p>If I am reading a white pixel, I stop the dust particles from being emitted because the rover is moving over rock, otherwise I made sure dust particles are emitted, because the rover is driving over dirt.</p>
            <div className="mb-5"></div>
            <Tools heading="Tools I used" toolNames={["unity", "blender", "rider", "photoshop"]}></Tools>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="mb-5 d-md-none"></div>
            <Gallery toggleOverlay={toggleOverlay} items={[
              {thumb:"do-rover/do-rover5-thumb.jpg", full:"do-rover/do-rover5.mp4", title:"A  demonstration of Do-Rover's basic gameplay loop.", type:"video"},
              {thumb:"do-rover/do-rover1-thumb.jpg", full:"do-rover/do-rover1.jpg", title:"Interface for buying a new rover (1).", type:"image"},
              {thumb:"do-rover/do-rover2-thumb.jpg", full:"do-rover/do-rover2.jpg", title:"Interface for buying a new rover (2).", type:"image"},
              {thumb:"do-rover/do-rover3-thumb.jpg", full:"do-rover/do-rover3.jpg", title:"Rovers from previous expeditions remain on the planets.", type:"image"},
              {thumb:"do-rover/do-rover4-thumb.jpg", full:"do-rover/do-rover4.jpg", title:"A rover charging inside the energy field of an energy pylon.", type:"image"}
            ]}></Gallery>
          </div>
        </div>
      </Section>
      
      <Section title="HP Global Citizen" subtitle="Augmented Reality" backgroundExt=".gif">
        <div className="mb-2"></div>
        <div className="row">
          <div className="col-lg-4 main-copy-col">
            <div className="main-copy">
              <p><span className="emphasise">HP Global Citizen</span>was an activation that invited mall visitors to experience a day in the wild with a large scale Augmented Reality experience. Mall goers were also encouraged to pledge their support for Equality, Education, and the Environment through interactive photo booths.</p>
              <p>I was contracted by <a href='https://mannmade.co.za/' target='_blank'>Mann Made</a>, the production house responsible for the activation, to develop the software for the AR Experience and the photo booths.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 secondary-copy">
            <h2>Nerdy Stuff</h2>
            <div className="mb-1"></div>
            <p>The Augmented reality part of the project was developed using Unity. The software allowed one to align the virtual elements to a real world camera-feed. It also provided ways to mask out parts of the virtual world where they might be moving behind some real-life objects</p>
            <p>A separate web-app was also developed. This web-app allowed interactions with the photo booths for capturing and printing photos. The photo booth software was also developed with Unity.</p>
            <p>Communication between the web-app and photo booths were made possible by a nodeJS server-app that would relay messages from the web-app to a photo booth.</p>
            <div className="mb-5"></div>
            <Tools heading="Tools I used" toolNames={["unity", "reactjs", "nodejs", "vscode"]}></Tools>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="mb-5 d-md-none"></div>
            <Gallery toggleOverlay={toggleOverlay} items={[
              {thumb:"hp-global-citizen/hp-global-citizen1-thumb.jpg", full:"hp-global-citizen/hp-global-citizen1.mp4", title:"The AR Experience in action.", type:"video"},
              {thumb:"hp-global-citizen/hp-global-citizen2-thumb.jpg", full:"hp-global-citizen/hp-global-citizen2.mp4", title:"Testing the AR Experience on the big screen for the first time.", type:"video"},
              {thumb:"hp-global-citizen/hp-global-citizen3-thumb.jpg", full:"hp-global-citizen/hp-global-citizen3.jpg", title:"The AR Experience and Photo Booths", type:"image"},
              {thumb:"hp-global-citizen/hp-global-citizen4-thumb.jpg", full:"hp-global-citizen/hp-global-citizen4.jpg", title:"Why is there a giraffe in the warehouse?", type:"image"},
              {thumb:"hp-global-citizen/hp-global-citizen5-thumb.jpg", full:"hp-global-citizen/hp-global-citizen5.jpg", title:"Environment pledge.", type:"image"},
              {thumb:"hp-global-citizen/hp-global-citizen6-thumb.jpg", full:"hp-global-citizen/hp-global-citizen6.jpg", title:"Equality pledge.", type:"image"},
              {thumb:"hp-global-citizen/hp-global-citizen7-thumb.jpg", full:"hp-global-citizen/hp-global-citizen7.jpg", title:"Education pledge.", type:"image"}
            ]}></Gallery>
          </div>
        </div>
      </Section>

      <Section title="Pandora Launch" subtitle="Interactive Installation" backgroundExt=".gif">
        <div className="mb-2"></div>
        <div className="row">
          <div className="col-lg-4 main-copy-col">
            <div className="main-copy">
              <p><span className="emphasise">Pandora</span>was launching their new range of rose gold jewelry and approached my employer at the time, <a href='https://mannmade.co.za/' target='_blank'>Mann Made</a>, to create some interactive artworks for the launch event.</p>
              <p>As the developer on the project, I was tasked with creating 6 unique interactive artworks across 3 themes, rose, autumn, and winter. The interactive part being that the  artworks had to react to the movement of passersby.</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 secondary-copy">
            <h2>Nerdy Stuff</h2>
            <div className="mb-1"></div>
            <p>Movement was tracked using a Microsoft Kinect. A Processing app would then process the movement data and update the artwork accordingly.</p>
            <p>Since this installation was for a live event, smooth performance was of utmost importance. Preformance optimisations were made by making use of shaders. This ensured that a lot of the heavy lifting was off-loaded to the GPU and unnecessary hiccups were avoided.</p>
            <div className="mb-5"></div>
            <Tools heading="Tools I used" toolNames={["processing", "photoshop"]}></Tools>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="mb-5 d-md-none"></div>
            <Gallery toggleOverlay={toggleOverlay} items={[
              {thumb:"pandora/pandora1-thumb.jpg", full:"pandora/pandora1.mp4", title:"Rose petal boids attracted to movement.", type:"video"},
              {thumb:"pandora/pandora2-thumb.jpg", full:"pandora/pandora2.mp4", title:"Kaleidoscope effect created using a shader and layered photographs.", type:"video"},
              {thumb:"pandora/pandora3-thumb.jpg", full:"pandora/pandora3.mp4", title:"Star scape rotated by detected movement to reveal a parallax effect.", type:"video"},
              {thumb:"pandora/pandora4-thumb.jpg", full:"pandora/pandora4.mp4", title:"Rose gold branches and leaves revealed by a torch that reacts to movement.", type:"video"},
              {thumb:"pandora/pandora5-thumb.jpg", full:"pandora/pandora5.jpg", title:"The launch event.", type:"image"}
            ]}></Gallery>
          </div>

        </div>
      </Section>

      <section className="contact-me">
        <div className="main-heading">
          <h1>Contact Me</h1>
        </div>
        <a href="mailto:henk@donderbos.com">henk@donderbos.com</a>
      </section>

      {/* <Footer></Footer> */}
    </div>
  );
}

export default App;
